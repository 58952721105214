#landing-bg-1 {
  background-image: url('/public/images/rocket-1.svg');
}

.landing-item:hover .landing-title{
  transition: opacity 1.5s;
  opacity: 1.0;
}

.landing-title {
  transition: opacity 0.5s;
  opacity: 0.0;
}

.landing-item:hover {
  transition-property: all;
  transition-duration: 1.0s;
  width: 98%;
  height: 98%;
  margin-left: 1.0%;
  margin-right: 1.0%;
  margin-top: 0.5%;
  background-position-x: 200%;
  background-position-y: -150%;
}

.landing-item {
  transition-property: all;
  transition-duration: 0.5s;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35% 50%;
}

.experience-title-block {
  background-color: #A5A1FF
}

.experience-description-block {
  background-color: #1B1B1F;
  color: #A5A1FF;
}

.about {
  background-color: #1F1A1C;
  color: #FFAED8
}


.project-item:hover {
  transition-property: all;
  transition-duration: 1.0s;
  width: 98%;
  height: 98%;
  margin-left: 1.0%;
  margin-right: 1.0%;
  margin-top: 0.5%;
}

.project-item {
  transition-property: all;
  transition-duration: 0.5s;
}